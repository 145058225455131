import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Moment from 'react-moment'
import ButtonLink from '../ButtonLink'

interface PostItemData {
  to: string
  img?: any
  alt?: any
  date: string
  terms: any
  className?: string
  children?: React.ReactNode
  classNameText?: string
  readMoreString?: string
}

const PostItemRow = ({
  to,
  img,
  alt,
  date,
  terms,
  className = '',
  classNameText = '',
  children,
  readMoreString = 'Lue lisää',
}: PostItemData) => {
  return (
    <div className={`flex flex-row ${className}`}>
      {img && (
        <GatsbyImage
          className="2xl:w-[calc(50%-.6rem)] flex-grow-0 flex-shrink-0 h-full object-cover pb-[23%]"
          image={img}
          alt={alt}
        />
      )}
      <div className={`flex flex-1 flex-col justify-between ${classNameText}`}>
        <div>
          <div className="flex text-2xl lg:text-4xl">
            <p>
              <Moment format="DD.MM.YYYY" date={date} />
            </p>
            {terms && (
              <p className="ml-auto flex">
                {terms.map((term: any, i: number, row: any) => {
                  if (i + 1 === row.length) {
                    return (
                      <span className="flex items-center" key={term.id}>
                        {term.name}
                      </span>
                    )
                  } else {
                    return (
                      <span className="after-line-news flex items-center mr-4" key={term.id}>
                        {term.name}
                      </span>
                    )
                  }
                })}
              </p>
            )}
          </div>
          {children}
        </div>
        <div className="mx-auto my-8">
          <ButtonLink className="uppercase" to={to} label={readMoreString} />
        </div>
      </div>
    </div>
  )
}

export default PostItemRow
