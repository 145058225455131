import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Moment from 'react-moment'
import ButtonLink from '../ButtonLink'

interface PostItemData {
  to: string
  img?: any
  alt?: any
  date: string
  terms: any
  className?: string
  children?: React.ReactNode
  classNameText?: string
  readMoreString?: string
}

const PostItem = ({
  to,
  img,
  alt,
  date,
  terms,
  className = '',
  classNameText = '',
  children,
  readMoreString = 'Lue lisää',
}: PostItemData) => {
  return (
    <div className={`flex flex-col relative ${className}`}>
      {img && <GatsbyImage className="h-full object-cover pb-[35%]" image={img} alt={alt} />}
      <div className={`absolute text-white w-full flex justify-between h-full flex-1 flex-col ${classNameText}`}>
        <div className="bg-primary/40 absolute top-0 left-0 w-full h-full" />
        <div className="flex text-2xl lg:text-4xl z-10">
          <p>
            <Moment format="DD.MM.YYYY" date={date} />
          </p>
          {terms && (
            <p className="ml-auto flex">
              {terms.map((term: any, i: number, row: any) => {
                if (i + 1 === row.length) {
                  return (
                    <span className="flex items-center" key={term.id}>
                      {term.name}
                    </span>
                  )
                } else {
                  return (
                    <span className="flex items-center mr-4" key={term.id}>
                      {term.name}
                    </span>
                  )
                }
              })}
            </p>
          )}
        </div>
        {children}
        <div className="mb-4 mx-auto text-primary">
          <ButtonLink className="uppercase" secondary to={to} label={readMoreString} />
        </div>
      </div>
    </div>
  )
}

export default PostItem
