import React, { useState, useEffect } from 'react'
import { getImage } from 'gatsby-plugin-image'
import PostItem from '@/components/PostItem'
import PostItemRow from '@/components/PostItem/PostItemRow'
import WheelSymbol from './assets/symbol-wheel.webp'

const excerpt = (content: any = '', number = 350, words = false) => {
  if (content) {
    const regex = /(<([^>]+)>)/gi
    const format = content?.replace(regex, '')
    const formatSpaces = format?.replace(/(\r\n|\n|\r)/gm, '').trim()
    const cutContent = words ? formatSpaces.split(' ').slice(0, number).join(' ') : formatSpaces.slice(0, number)

    const addDots = formatSpaces.length > cutContent.length ? '...' : ''
    const final = `${cutContent}${addDots}`

    return final
  } else {
    return ''
  }
}

interface LoadMore {
  posts: any
  className?: string
  classNameButton?: string
  total?: number
  load?: number
  buttonText?: string
  reduceByone?: boolean
  readMoreString?: string
}

const LoadMore = ({
  posts,
  load = 4,
  reduceByone = false,
  className = '',
  classNameButton = '',
  buttonText = 'Katso lisää',
  readMoreString = 'Lue lisää',
}: LoadMore) => {
  const [reduce, setReduce] = useState(0)
  const numberPosts = load - reduce || 4
  const [paginatedPosts, setPaginatedPosts] = useState([...posts.slice(0, numberPosts)])
  const [loadMore, setLoadMore] = useState(false)

  const [hasMore, setHasMore] = useState(posts.length > numberPosts)

  const handleLoadMore = () => {
    setLoadMore(true)
  }
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = paginatedPosts.length
      const isMore = currentLength < posts.length
      const nextResults = isMore ? posts.slice(currentLength, currentLength + numberPosts) : []
      setPaginatedPosts([...paginatedPosts, ...nextResults])
      setLoadMore(false)
    }
    if (reduceByone) {
      setReduce(1)
    }
  }, [loadMore, hasMore, numberPosts, paginatedPosts, posts, reduceByone])

  useEffect(() => {
    const isMore = paginatedPosts.length < posts.length
    setHasMore(isMore)
  }, [paginatedPosts, posts.length])

  return (
    <>
      <div className={`${className}`}>
        {paginatedPosts?.map((post: any, index: number) => {
          const image: any = getImage(post?.featuredImage?.node?.localFile)
          const alt: string = post?.featuredImage?.node?.altText || 'post image'

          if (index === 0) {
            return (
              <PostItemRow
                readMoreString={readMoreString}
                key={post.id}
                to={post.uri}
                img={image}
                alt={alt}
                date={post.date}
                className={`sm:col-span-2 flex-col 2xl:flex-row`}
                classNameText="px-0 py-8 sm:p-10"
                terms={post?.terms?.nodes}
              >
                <h3 className="z-10 text-4xl md:text-6xl mt-4 lg:mb-4 break-words leading-8 md:leading-10">
                  {post.title}
                </h3>
                <p className="font-helvetica text-md md:text-2xl">{excerpt(post.excerpt)}</p>
              </PostItemRow>
            )
          } else {
            const shortTitle = post.title.length > 50 ? post.title.substr(0, 50 - 1) + '...' : post.title
            return (
              <PostItem
                readMoreString={readMoreString}
                key={post.id}
                to={post.uri}
                img={image}
                alt={alt}
                date={post.date}
                classNameText="px-6 py-8"
                terms={post?.terms?.nodes}
              >
                <h3 className="z-10 h-full leading-none md:text-8xl mt-4 break-words xl:p-16 xl:pr-24 text-4xl xl:text-6xl 2xl:text-8xl text-white 2xl:leading-[5rem]">
                  {shortTitle}
                </h3>
              </PostItem>
            )
          }
        })}
      </div>

      <div className={`${classNameButton}`}>
        {hasMore ? (
          <div className="flex gap-4 items-center group">
            <img
              width="60"
              height="60"
              className="w-12 h-12 md:h-16 md:w-16 group-hover:rotate-12 transform duration-500 ease-in-out"
              src={WheelSymbol}
              alt=""
            />
            <button
              className={`uppercase inline-block border-4 px-4 pb-1 pt-2 2xl:px-9 2xl:pb-[0.675rem] 2xl:pt-[1.125rem] 2xl:leading-[2.3rem] text-2xl md:text-4xl lg:text-5xl rounded-full 2xl:rounded-3xl`}
              onClick={handleLoadMore}
            >
              {buttonText}
            </button>
          </div>
        ) : (
          <p className="mb-12 uppercase inline-block px-4 pb-1 pt-2 2xl:px-9 2xl:pb-[0.675rem] 2xl:pt-[1.125rem] 2xl:leading-[2.3rem] text-4xl lg:text-5xl rounded-full 2xl:rounded-3xl">
            Ei enempää julkaisuja
          </p>
        )}
      </div>
    </>
  )
}
export default LoadMore
