import React, { useEffect, useState, useRef } from 'react'

const Select = ({ children, className = '', selectName, activeSelect }: any) => {
  const [show, setShow] = useState(false)

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const change = () => setShow(prev => !prev)

  const active = activeSelect == '' ? selectName : activeSelect

  useEffect(() => {
    setShow(false)
  }, [activeSelect])

  return (
    <div className={`relative w-full lg:max-w-xs ${className}`} ref={wrapperRef}>
      <button
        onClick={change}
        className="w-full lg:text-2xl border-black tracking-full border-solid border-2 lowercase flex items-center font-helvetica px-12 py-3"
      >
        <span className="capitalize-first w-full text-center">{active}</span>
      </button>
      {show && (
        <div className="absolute shadow-xl bg-white lg:rounded-md py-5 z-10 bg-latte flex-col flex text-xl w-full font-helvetica space-y-4">
          {children}
        </div>
      )}
    </div>
  )
}
export default Select
