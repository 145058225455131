import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Seo from '@/components/Seo'
import { Seo as SeoType } from '@/types.d'
import moment from 'moment'
import Select from '@/components/Select'
import LoadMore from '@/components/LoadMore'

interface HomeData {
  data: {
    allWpGwWheelInfo: any
    page: {
      heroTitle: string
      title: string
      content: string
      uri: string
      featuredHeroImage?: any
      seo: SeoType
      featuredImage: any
      blocks: any
      blocksJSON: any
    }
    mainMenu?: any
    allWpPost: any
  }
  pageContext: {
    postlang: string
  }
}

const clearQuerySrings = () => {
  if (typeof window === 'undefined') return

  window.history.pushState(
    { path: window.location.protocol + '//' + window.location.host + window.location.pathname },
    '',
    window.location.protocol + '//' + window.location.host + window.location.pathname
  )
}

export const getQuerySring = () => {
  if (typeof location === 'undefined') return ''

  const params = new URLSearchParams(location.search)
  const s = params?.get('s')

  return s
}

/**
 * Data for year switcher
 */
const getYearFilterValues = (nodes: any) => {
  // Get all years from posts
  const years = nodes.map((node: { date: string }) => moment(node.date).format('YYYY'))

  // Sort so that we start with biggest
  const sorted = years.sort((a: number, b: number) => b - a)

  // Return array without duplicates
  const filtered: string[] = Array.from(new Set(sorted))

  return filtered
}

const PostsArchive = ({ data }: HomeData) => {
  // Page data
  const { page, allWpPost, mainMenu } = data

  const yearFilterValues = getYearFilterValues(allWpPost?.nodes)

  const heroContent = {
    image: page?.featuredImage,
    archive: true,
  }

  const [year, setYear] = useState('')

  clearQuerySrings()

  const setYearFilter = (currentYear: string) => setYear(currentYear)

  const clearFilters = () => setYear('')

  const filterDate = (item: any) => {
    if (moment(item.date).format('YYYY') === year || year === '') {
      return true
    }
  }

  const filterResults = () => {
    const filterYear: any = allWpPost?.nodes.filter(filterDate)
    const FilterTerm: any = filterYear
    return FilterTerm
  }

  const results = filterResults()

  const testkey = new Date()
  const loadmoreKey = testkey.getTime()

  const title = 'Artikkelit'

  return (
    <Layout title={page?.heroTitle || page?.title} hero={heroContent} nav={mainMenu}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <div className="flex flex-col lg:flex-row justify-between w-full lg:items-center my-16">
        <h2 className="text-5xl lg:text-8xl">{title}</h2>

        {yearFilterValues.length > 1 && (
          <Select selectName="Valitse vuosi" activeSelect={year}>
            <button className={`${year == '' ? 'undeline' : ''} hover:underline`} onClick={clearFilters}>
              Kaikki
            </button>

            {yearFilterValues.map((filterValue: string) => (
              <button
                className={`${year == filterValue ? 'underline' : ''} hover:underline`}
                key={filterValue}
                onClick={() => setYearFilter(filterValue)}
              >
                {filterValue}
              </button>
            ))}
          </Select>
        )}
      </div>

      {results && (
        <LoadMore
          readMoreString="Lue lisää"
          reduceByone={true}
          load={3}
          key={loadmoreKey}
          classNameButton="flex items-center justify-center my-20"
          className="mt-4 sm:mt-6 flex flex-col 2xl:grid gap-4 2xl:grid-cols-2 2xl:gap-6"
          posts={results}
          buttonText="Katso lisää"
        />
      )}
    </Layout>
  )
}

export const PostsArchiveQuery = graphql`
  query PostsArchive($id: String) {
    page: wpPage(id: { eq: $id }) {
      heroTitle
      id
      content
      title
      uri
      ...featuredHeroImage
      ...seo
    }
    mainMenu: wpMenu(slug: { eq: "main-menu" }) {
      ...navigation
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      ...allPosts
    }
  }
`

export default PostsArchive
